




















































import useApi from "@/use/api";
import useUser from "@/use/user";
import { defineComponent, onMounted, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: {
    MCompanyInput: () => import('@/components/molecules/m-company-input.vue'),
    MDatePicker: () => import('@/components/molecules/m-date-picker.vue')
  },
  setup(_, { root }) {
    const { hasAccessTo } = useUser({ root });
    const { axiosInstance } = useApi({ root });

    const structure = ref<{ [key: string]: any } | null>(null)
    const companies = ref<any[]>([])

    const form = ref()
    const isFormValid = ref()

    const loading = ref(false);
    const error = ref<null | string>(null)

    const model = reactive({
      company: null as any,
      name: ''
    })

    const updateModel = () => {
      if (structure.value && structure.value.company && !companies.value.find((c: any) => c.id === structure.value?.company)) {
        companies.value.push(structure.value.company)
      }

      model.company = structure.value?.company || null
      model.name = structure.value?.name || ''
    }

    watch(() => structure.value, updateModel, { deep: true })


    const fetch = () => {
      error.value = null
      loading.value = true

      axiosInstance
        .get(`company-structure/${root.$route.params.id}`)
        .then(({ data: { structure: structureData } }) => {
          structure.value = structureData
        })
        .catch(() => {
          error.value = 'Nie można pobrać struktury'
        })
        .finally(() => loading.value = false)
    }

    onMounted(fetch)
    watch(() => root.$route.params.id, fetch)

    const submit = async () => {
      error.value = null
      loading.value = true

      await form.value?.validate()

      if (isFormValid.value) {
        const formData = {
          company: model.company?.id ?? undefined,
          name: model.name ?? undefined
        }

        axiosInstance
          .put(`company-structure/${root.$route.params.id}`, formData)
          .then(() => {
            root.$router.push({ name: 'panel.company-structure.list' })
          })
          .catch(() => {
            error.value = 'Nie można dodać struktury'
          })
          .finally(() => loading.value = false)
      } else {
        loading.value = false
      }
    }

    return { structure, companies, hasAccessTo, form, isFormValid, loading, error, model, submit };
  },
});
